import {
  BaseSalaryComponent,
  BehaviourSettings,
  DeductionType,
  DeductionsComponentType,
  EarningPayType,
  EarningsComponentType,
  SalaryComponent,
  SalaryComponentCategory,
} from 'components/SalaryComponents/types';

import { DeductionType as RunPayrollDeductionType } from 'components/RunPayroll/utils/types';

export type PayrollCreateOrUpdateSalaryComponentResponse = {
  code: number;
  data?: Partial<SalaryComponent>;
  message?: string;
  status_code?: string;
  success: boolean;
};

export type PayrollCreateOrUpdateSalaryComponentVariables = (
  | Omit<EarningsComponentType, 'id'>
  | Omit<DeductionsComponentType, 'id'>
) & {
  id?: string;
};

export type PayrollUpdateSalaryComponentVariables = BaseSalaryComponent & {
  settings: BehaviourSettings;
  payType?: EarningPayType;
};

export type SalaryComponentStatusType =
  | 'COMPONENT_STATUS_NONE'
  | 'COMPONENT_STATUS_ACTIVE'
  | 'COMPONENT_STATUS_INACTIVE';

export type PayTypeFilterType =
  | 'LIST_ADHOC_TYPE'
  | 'LIST_VARIABLE_TYPE'
  | 'LIST_FIXED_TYPE'
  | 'LIST_RECURRING_TYPE';

export type SalaryComponentSetStatusType = 'SET_STATUS_ACTIVE' | 'SET_STATUS_INACTIVE';

export type TaxabilityFilterType = {
  taxExemptInOldRegime: boolean;
  taxExemptInNewRegime: boolean;
}

export type PayrollListSalaryComponentsQueryVariables = {
  category: SalaryComponentCategory;
  status: SalaryComponentStatusType;
  payType?: PayTypeFilterType;
  taxability?: TaxabilityFilterType;
  deductionType?: DeductionType;
};

export type GetEmployeeAdditionsDeductionsQueryVariables = {
  userId: string;
  effectiveFrom: string;
  effectiveTo: string;
};

type BaseResponse<T> = {
  code: number;
  data: T;
  message: string;
  success: boolean;
};

// Generic GraphQL wrapper type
export type GraphQLResponse<K extends string, T> = {
  [key in K]: BaseResponse<T>;
};

export type GetEmployeeAdditionsDeductionsResponse = GraphQLResponse<
  'payrollGetEmployeeAdditionsDeductions',
  GetEmployeeAdditionsDeductionsResponseContent
>;

export type UpdateEmployeeAdditionsDeductionsResponse = GraphQLResponse<
  'payrollUpdateEmployeeAdditionsDeductions',
  GetEmployeeAdditionsDeductionsResponseContent
>;

export type ListAdditionsResponse = GraphQLResponse<
  'payrollListAdditions',
  SalaryComponentAssignmentResponse<EarningsComponentType>
>;

export type ListDeductionsResponse = GraphQLResponse<
  'payrollListDeductions',
  SalaryComponentAssignmentResponse<DeductionsComponentType>
>;

export type UpdateAdditionsResponse = GraphQLResponse<
  'payrollUpdateAdditions',
  SalaryComponentAssignmentResponse<EarningsComponentType>
>;

export type SalaryComponentAssignmentResponse<
  T extends EarningsComponentType | DeductionsComponentType,
> = {
  data: AssignmentResponseData<T>[];
  source: string;
};

export type GetEmployeeAdditionsDeductionsResponseContent = {
  additions: AssignmentResponseData<EarningsComponentType>[];
  deductions: AssignmentResponseData<DeductionsComponentType>[];
  lopData: {
    peopleId: string;
    lopDays: number;
    source: LopSource;
  }[];
};

export type AssignmentResponseData<T extends EarningsComponentType | DeductionsComponentType> = {
  id: string;
  userId: string;
  type: string;
  amount: number;
  effectiveFrom: string;
  effectiveTo: string;
  componentDetails: T;
};

export type ComponentAssignmentRequestInput = {
  componentId: string;
  value: string;
  effectiveFrom: string;
  effectiveTo: string;
};

type SetSalaryComponentStatusResponseObject = {
  isActive: boolean;
  source: string;
};

export type SetSalaryComponentStatusResponse = GraphQLResponse<
  'payrollSetSalaryComponentStatus',
  SetSalaryComponentStatusResponseObject
>;

export type PayrollGetSalaryComponentQueryVariables = {
  id: string;
  category: SalaryComponentCategory;
  payType?: EarningPayType;
};

type SalaryComponentsListData<T> = {
  source: string;
  salaryComponents: T[];
};

export type ListSalaryComponentsResponse<T = SalaryComponent> = GraphQLResponse<
  'payrollListSalaryComponents',
  SalaryComponentsListData<T>
>;

type SalaryComponentData<T> = {
  source: string;
  salaryComponent: T;
};

export type GetSalaryComponentResponse<T = SalaryComponent> = GraphQLResponse<
  'payrollGetSalaryComponent',
  SalaryComponentData<T>
>;

export type PayrollUpdateAdditionsRequest = {
  assignments: {
    userId: string;
    additions: {
      componentId: string;
      value: string;
      effectiveFrom: string;
      effectiveTo: string;
    }[];
  };
};

export type PayrollUpdateEmployeesAdditionsDeductionsLopRequest = {
  userId: string;
  additions: {
    componentId: string;
    value: string;
    effectiveFrom: string;
    effectiveTo: string;
  }[];
  deductions: {
    componentId: string;
    value: string;
    effectiveFrom: string;
    effectiveTo: string;
  }[];
  lopData: LopData;
};

export type PayrollGetFnfDataVariables = {
  userId: string;
  deactivateDate: string;
};

export type PayrollGetUserByIdArgs = {
  userId: string;
};

export type UpdateLopDataItem = {
  peopleId: number;
  lopDays: number;
};

export type GetLopDataItem = {
  peopleId: string;
  lopDays: number;
  source: LopSource;
  payrollMonth: string;
  id: string;
  organizationId: number;
};

export type LopSource = 'manual' | 'manual-attendance' | 'fnf' | 'auto-attendance';

export const LopSourceToDeductionTypeMap: Record<LopSource, RunPayrollDeductionType> = {
  'manual' : RunPayrollDeductionType.TYPE_MANUAL,
  'manual-attendance' : RunPayrollDeductionType.TYPE_MANUAL_ATTENDANCE_LOP,
  'fnf': RunPayrollDeductionType.TYPE_MANUAL,
  'auto-attendance' : RunPayrollDeductionType.TYPE_AUTO_ATTENDANCE_LOP,
};

export type LopData = {
  payrollMonth: string;
  data: UpdateLopDataItem[];
  source: LopSource;
};

export type PayrollPeopleOrganization = {
  advanceSalary?: number;
  bankNameId?: number;
  communicationPreferences?: Record<string, unknown>;
  contactPreferences?: Record<string, unknown>;
  createdAt?: string;
  customSalaryStructure?: Record<string, unknown>;
  deactivateDate?: string;
  department?: string;
  dob?: string;
  emi?: number;
  employeeId?: string;
  employeeTdsOverridePurpose?: string;
  employeeType?: string;
  epContractorPaid?: boolean;
  esiIp?: string;
  flexibleBenefitsAmountHold?: number;
  gender?: string;
  hireDate?: string;
  id?: string;
  idPrefix?: string;
  imprest?: number;
  isActive?: boolean;
  isCompany?: boolean;
  isDeleted?: boolean;
  isEsiCompulsory?: boolean;
  isEsiDisabled?: boolean;
  isEsicAdded?: boolean;
  isLoginEnabled?: boolean;
  isPf?: boolean;
  isPfAdded?: boolean;
  isPfEpsEnabled?: boolean;
  isPt?: boolean;
  isResidentOfIndia?: boolean;
  lastLogin?: string;
  leaves?: Record<string, unknown>;
  location?: number;
  locationLwf?: number;
  locationPt?: number;
  managerPeopleId?: string;
  name?: string;
  noTds?: boolean;
  notes?: string;
  npsConfig?: Record<string, unknown>;
  npsPran?: string;
  optionalUserData?: UserOptionalFieldData;
  organizationId?: string;
  pastExemption?: number;
  pastSalary?: number;
  pastTds?: number;
  peopleId?: string;
  perquisites?: Record<string, unknown>;
  pfLimit?: number;
  phoneNumber?: string;
  photo?: string;
  previousEmployerSalary?: number;
  previousEmployerTds?: number;
  reasonForLeaving?: string;
  salary?: number;
  salaryModel?: Record<string, unknown>;
  shiftEnd?: string;
  shiftStart?: string;
  stopSalary?: boolean;
  title?: string;
  uan?: string;
  uiPreferences?: Record<string, unknown>;
  updatedAt?: string;
  userRoleId?: number;
  variablePay?: number;
};

export type UserOptionalFieldData = {
  child1: string | null;
  child2: string | null;
  custom1: string | null;
  custom2: string | null;
  custom3: string | null;
  custom4: string | null;
  custom5: string | null;
  custom6: string | null;
  custom7: string | null;
  custom8: string | null;
  custom9: string | null;
  custom10: string | null;
  custom11: string | null;
  custom12: string | null;
  custom13: string | null;
  custom14: string | null;
  custom15: string | null;
  bloodGroup: string | null;
  fathersDob: string | null;
  mothersDob: string | null;
  spousesDob: string | null;
  fathersName: string | null;
  mothersName: string | null;
  nationality: string | null;
  spousesName: string | null;
  aadhaarNumber: string | null;
  maritalStatus: string | null;
  workExperience: string | null;
  permanentAddress: string | null;
  previousEmployer: string | null;
  temporaryAddress: string | null;
  marriageAnniversary: string | null;
  personalPhoneNumber: string | null;
  previousDesignation: string | null;
  emergencyContactName: string | null;
  highestQualification: string | null;
  personalEmailAddress: string | null;
  emergencyContactNumber: string | null;
  emergencyContactRelation: string | null;
  section197CertificateNumber: string | null;
};

export type UserResponseData = {
  id: string;
  name: string;
  organizationId: string;
  peopleId: string;
  hireDate: string;
  title: string;
  deactivateDate: string | null;
  location: string;
  employeeId: string;
  optionalUserData: UserOptionalFieldData;
  department: string;
  idPrefix: string;
  uiPreferences: Record<string, unknown>;
  reasonForLeaving: string | null;
};

export type PayrollStagingStatus =
  | 'created'
  | 'finalized'
  | 'executed'
  | 'failed'
  | 'skipped'
  | 'none';

export type UserPayrollStatuses = {
  usersStatus: Array<{
    userId: string;
    status: PayrollStagingStatus;
  }>;
  isOrgFinal: boolean;
};

export type PayrollGetFnfDataResponse = GraphQLResponse<
  'payrollGetFnfData',
  {
    additions: AssignmentResponseData<EarningsComponentType>[];
    deductions: AssignmentResponseData<DeductionsComponentType>[];
    lopData: GetLopDataItem[];
    userData: PayrollPeopleOrganization;
    userStatuses: UserPayrollStatuses;
  }
>;

export type PayrollUpdateFnfDataVariables = {
  deactivateDate: string;
  additions: ComponentAssignmentRequestInput[];
  deductions: ComponentAssignmentRequestInput[];
  lopData: LopData;
  userData: {
    userId: string;
    personalEmailAddress?: string | null;
    reasonForLeaving?: string | null;
    deactivateDate: string;
  };
};

export type PayrollUpdateFnfDataResponse = GraphQLResponse<
  'payrollUpdateFnfData',
  {
    success: boolean;
    payslip_link: string;
    payroll_month: string;
    runpayroll_link: string;
    message_type: string;
  }
>;
export type PayrollSetSalaryComponentStatusRequest = {
  id: string;
  category: SalaryComponentCategory;
  status: SalaryComponentSetStatusType;
  earningsPayType?: EarningPayType;
};
