import { TabItem, TabList, Tabs } from '@razorpay/blade/components';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { tabItems } from '../constants';

export const HeaderTabs = () => {
  const location = useLocation();
  const history = useHistory();
  const currentTab = location.pathname.split('/')[2];
  return (
    <Tabs
      variant="bordered"
      orientation="horizontal"
      size="medium"
      onChange={(value) => history.push(`/pay-details/${value}`)}
      defaultValue={tabItems.compensation}
      value={currentTab}>
      <TabList>
        <TabItem value={tabItems.payslips}>Payslips</TabItem>
        <TabItem value={tabItems.compensation}>Compensation</TabItem>
        <TabItem value={tabItems.form16}>Form-16</TabItem>
      </TabList>
    </Tabs>
  );
};