import {
  Amount,
  Box,
  Button,
  Card,
  CardBody,
  DotIcon,
  MinusCircleIcon,
  SparklesIcon,
  Text,
  UserIcon,
} from '@razorpay/blade/components';
import { bladeTheme } from '@razorpay/blade/tokens';
import { DonutChart } from 'components/ui/DonutChart';
import React from 'react';
import { SummaryCard } from './SummaryCard';

const data = [
  { percentage: 70, color: bladeTheme.colors.onDark.interactive.icon.positive.subtle }, // Green
  { percentage: 20, color: bladeTheme.colors.onDark.interactive.icon.information.normal }, // Blue
  { percentage: 10, color: bladeTheme.colors.onDark.interactive.icon.notice.normal }, // Orange
];

export const SummarySection = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.5'} testID='summary-section'>
      <Card padding="spacing.7" borderRadius="large">
        <CardBody>
          <Box
            display={'flex'}
            flexDirection={{ base: 'column', m: 'row' }}
            justifyContent={'space-between'}
            gap={'48px'}>
            <Box
              alignItems={'center'}
              width={'100px'}
              height={'170px'}
              marginLeft={{ base: '60px', m: 'spacing.8' }}>
              <DonutChart data={data} size={170} strokeWidth={20} />
            </Box>
            <Box
              minWidth={{ base: 'auto', m: '458px' }}
              display={'flex'}
              flexDirection={'column'}
              gap={'spacing.7'}>
              <Box
                display={'flex'}
                flexDirection={{ base: 'column', m: 'row' }}
                gap={{ base: 'spacing.4' }}
                justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Text weight="medium" color={'interactive.text.gray.muted'}>
                    Annual CTC
                  </Text>
                  <Amount
                    type={'heading'}
                    size="2xlarge"
                    weight="semibold"
                    currencyIndicator="currency-symbol"
                    currency="INR"
                    suffix={'decimals'}
                    value={3600000}
                  />
                </Box>
                <Box width={'118px'}>
                  <Button variant={'primary'} isFullWidth>
                    Revise CTC
                  </Button>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                width={'100%'}
                justifyContent={'space-between'}
                gap={'spacing.3'}>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.positive.subtle'} />
                    <Text color={'surface.text.gray.muted'}>Fixed</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    currencyIndicator="currency-symbol"
                    currency="INR"
                    suffix={'decimals'}
                    value={3000000}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.information.normal'} />
                    <Text color={'surface.text.gray.muted'}>Variable</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    currencyIndicator="currency-symbol"
                    currency="INR"
                    suffix={'decimals'}
                    value={500000}
                  />
                </Box>
                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    gap={'spacing.2'}
                    alignItems={'center'}>
                    <DotIcon size="small" color={'interactive.icon.notice.normal'} />
                    <Text color={'surface.text.gray.muted'}>Other CTC component</Text>
                  </Box>
                  <Amount
                    type={'heading'}
                    size="small"
                    weight="semibold"
                    currencyIndicator="currency-symbol"
                    currency="INR"
                    suffix={'decimals'}
                    value={100000}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </CardBody>
      </Card>
      <Box display="flex" flexDirection={{ base: 'column', m: 'row' }} gap={'spacing.5'}>
        <SummaryCard
          icon={<MinusCircleIcon size="medium" />}
          title="Recurring deduction"
          amount={3000}
        />
        <SummaryCard icon={<SparklesIcon size="medium" />} title="Perquisites" amount={1065000} />
        <SummaryCard icon={<UserIcon size="medium" />} title="FBP allocated" amount={3000} />
      </Box>
    </Box>
  );
};