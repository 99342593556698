import cx from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IGenericErrorState } from '../../reducers/genericError';
import { ILoggedInUser } from '../../reducers/loggedInUser';
import { ISidebar } from '../../reducers/sidebar';
import { useNetworkStatus } from '../../utils/useNetworkStatus';
import useReduxSelector from '../../utils/useReduxSelector';
import { GlobalNotification } from './GlobalNotification';
import { pagesWithoutHeader, pagesWithoutLoggedInData, pagesWithoutSideBar } from './constants';
import './tailwind.css';
import { SideBarV2 } from './SideBar/SideBarV2';
import HeaderV2 from './Header/HeaderV2';
import { GlobalSearchProps } from 'components/GlobalSearch/types';
import { AppShell, DashboardContent } from './AppMain';

export function BaseShell({
  location,
  children,
  loggedInUser,
  genericError,
  networkStatus,
  sidebar,
}: {
  location: ReturnType<typeof useLocation>;
  children: React.ReactNode;
  loggedInUser: ILoggedInUser;
  genericError: IGenericErrorState;
  networkStatus: boolean;
  sidebar: ISidebar;
}) {
  const doesPageRequireLogin = !pagesWithoutLoggedInData.includes(location.pathname);
  const showSideBar = !pagesWithoutSideBar.includes(location.pathname);
  const showHeader = !pagesWithoutHeader.includes(location.pathname);
  const [isMobileSideBarOpen, setIsMobileSideBarOpen] = React.useState(false);
  const peopleId = loggedInUser.data?.currentOrganization?.employeeDetails?.peopleId;
  const permissions = (loggedInUser.data?.currentOrganization?.employeeDetails?.permissions ||
    []) as GlobalSearchProps['permissions'];
  const specialPlatformPermissions = (loggedInUser.data?.specialPlatformPermissions ||
    []) as GlobalSearchProps['specialPlatformPermissions'];
  const userType = (loggedInUser.data?.currentOrganization?.employeeDetails?.employeeType ||
    '') as GlobalSearchProps['userType'];
  const features = loggedInUser.data?.currentOrganization?.features || {};
  const hasMultipleOrgs = loggedInUser.data?.hasMultipleOrgs || false;

  const closeSidebar = () => {
    setIsMobileSideBarOpen(false);
  };

  const toggleMobileSideBar = () => {
    setIsMobileSideBarOpen((isOpen) => !isOpen);
  };

  return (
    <>
      {showHeader && (
        <HeaderV2
          doesPageRequireLogin={doesPageRequireLogin}
          name={loggedInUser.data?.currentOrganization?.employeeDetails?.name || ''}
          companyBrandName={loggedInUser.data?.currentOrganization?.brandName || ''}
          companyLogo={loggedInUser.data?.currentOrganization?.logo || ''}
          userType={userType}
          permissions={permissions}
          specialPlatformPermissions={specialPlatformPermissions}
          peopleId={peopleId}
          orgId={loggedInUser.data?.currentOrganization?.id}
          userPhoto={
            loggedInUser.data?.currentOrganization?.employeeDetails?.photo as
              | string
              | null
              | undefined
          }
          features={features}
          hasMultipleOrgs={hasMultipleOrgs}
          toggleMobileSideBar={toggleMobileSideBar}
          roleName={loggedInUser.data?.currentOrganization?.employeeDetails?.roleName}
        />
      )}
      <GlobalNotification genericError={genericError} networkStatus={networkStatus} />
      <AppShell className="app__shell">
        {showSideBar && (
          <SideBarV2
            sidebar={sidebar.data}
            isMobileSideBarOpen={isMobileSideBarOpen}
            closeSidebar={closeSidebar}
          />
        )}

        <DashboardContent
          isSideBarOpen={showSideBar}
          noHeader={!showHeader}
          className={'app__main'}>
          {children}
        </DashboardContent>
      </AppShell>
    </>
  );
}

export default function BaseShellContainer({ children }: { children: React.ReactNode }) {
  let loggedInUser = useReduxSelector((state) => state.loggedInUser);

  let genericError = useReduxSelector((state) => state.genericError);

  const sidebar = useReduxSelector((state) => state.sidebar);
  const location = useLocation();
  let networkStatus = useNetworkStatus();
  if (genericError.errors.length > 0) {
    throw new Error(genericError.errors[0].internalMessage);
  }

  return (
    <BaseShell
      sidebar={sidebar}
      loggedInUser={loggedInUser}
      genericError={genericError}
      networkStatus={networkStatus}
      location={location}>
      {children}
    </BaseShell>
  );
}
