import React, { useState } from 'react';
import { Table } from '../components/Table';
import {
  Box,
  Button,
  CheckCircleIcon,
  CheckIcon,
  Checkbox,
  CloseIcon,
  Text,
  Badge,
  RefreshIcon,
  ProgressBar,
  Link,
} from '@razorpay/blade/components';
import { Pagination } from '../components/Table/Pagination';
import { Column, RowClickHandler } from '../components/Table/types';
import { useWorkflowDetailsView } from '../useWorkflowDetailsView';
import { useApproveRejectRequests } from '../useApproveRejectRequests';
import { NoResultsTableView } from '../components/NoResultsTableView';
import { ErrorResultsTableView } from '../components/ErrorResultsTableView';
import { useQuery, useQueryClient } from 'react-query';
import api from 'api';
import { WorkflowTypesMap } from 'components/ApprovalWorkflows/constants';
import { useFilters } from '../useFilters';
import { invalidateEditPayrollCache, transformEditPayrollRequestData } from './utils';
import { GetWorkflowRequestsData } from 'api/workflows';
import { WorkflowRequestsCacheKeys, WorkflowRequestsStatusLabelsMap } from '../constants';
import { AllRowsCheckSkeleton } from '../components/AllRowsCheckSkeleton';
import { EditPayrollListItem } from './types';
import { NewToOldAmount } from '../components/NewToOldAmount';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { transformDataForWorkflowRequests } from 'api/gql-utils/workflows/transformers';
import { usePagination } from 'hooks/usePagination';

// @TODO - update the column render function according to the UI
export const editPayrollPendingTableColumns: Column<EditPayrollListItem>[] = [
  {
    title: 'Employee Details',
    render: (item) => {
      const cellData = item.employeeDetails;
      return (
        <Box display="flex" flexDirection="column">
          <Text color="surface.text.gray.muted">{cellData.name}</Text>
          <Text variant="caption" color="surface.text.gray.muted" size="small">
            {cellData.employeeId}
          </Text>
        </Box>
      );
    },
  },
  {
    title: 'Approval Level',
    render: (item) => {
      return <Badge color="notice">L{item.approvalLevel.toString()}</Badge>;
    },
    styles: {
      textAlign: 'center',
    },
  },
  {
    title: 'Payroll Month',
    render: (item) => {
      return <Text color="surface.text.gray.muted">{item.payrollMonth}</Text>;
    },
  },
  {
    title: 'Deductions',
    render: (item) => {
      return (
        <NewToOldAmount newAmount={item.deductions.newSum} oldAmount={item.deductions.oldSum} />
      );
    },
    styles: {
      textAlign: 'right',
    },
  },
  {
    title: 'Arrears',
    render: (item) => {
      return <NewToOldAmount newAmount={item.arrears.new} oldAmount={item.arrears.old} />;
    },
    styles: {
      textAlign: 'right',
    },
  },
  {
    title: 'Additions',
    render: (item) => {
      return <NewToOldAmount newAmount={item.additions.newSum} oldAmount={item.additions.oldSum} />;
    },
    styles: {
      textAlign: 'right',
    },
  },
];

const PendingApproval = () => {
  const queryClient = useQueryClient();
  const { workflowRequestId } = useWorkflowDetailsView();
  const [tableData, setTableData] = useState<EditPayrollListItem[]>([]);
  const actionsWithActiveWorkflows = useSelector(loggedInUserSelectors.actionsWithActiveWorkflows);
  const features = useSelector(loggedInUserSelectors.features);
  const isGraphQLEnabled = !!features?.['graphql'];

  const { currentPage, itemsPerPage, setCurrentPage, setItemsPerPage } = usePagination({
    page: 1,
    limit: 10,
  });

  const { currentFilters } = useFilters();
  const queryParams: GetWorkflowRequestsData = {
    ...currentFilters,
    action_type: WorkflowTypesMap.EDIT_PAYROLL,
    'status[]': [WorkflowRequestsStatusLabelsMap.pending],
    is_my_approval: 1,
  };

  const { data, refetch, isLoading, isFetching, isError } = useQuery({
    queryKey: [WorkflowRequestsCacheKeys.editPayroll.pending, queryParams],
    queryFn: () => api.workflowApis.getRequests(queryParams, isGraphQLEnabled),
    onSuccess: ({ data, people_metadata }) => {
      const tableData =
        data?.map((rowData) => transformEditPayrollRequestData(rowData, people_metadata)) ?? [];
      setTableData(tableData);
    },
    select: transformDataForWorkflowRequests,
    cacheTime: 0,
  });

  const {
    onRowChecked,
    actionCtasDisabled,
    selectedRowsCount,
    handleMultiApproveCtaClicked,
    handleMultiRejectCtaClicked,
    handleSingleApproveClicked,
    handleSingleRejectClicked,
  } = useApproveRejectRequests({ tableData, setTableData });

  const { openDetailsView } = useWorkflowDetailsView();

  const onRowClicked: RowClickHandler<EditPayrollListItem> = (rowData) => {
    const id = rowData.id;

    openDetailsView(id);
  };

  const hoverTray = (rowData: EditPayrollListItem) => {
    return (
      <Box display="flex" gap="spacing.6">
        <Button
          size="xsmall"
          variant="tertiary"
          icon={CloseIcon}
          iconPosition="left"
          onClick={(e) => {
            e.stopPropagation();
            handleSingleRejectClicked(rowData, () => invalidateEditPayrollCache(queryClient));
          }}>
          REJECT
        </Button>
        <Button
          size="xsmall"
          variant="secondary"
          icon={CheckIcon}
          iconPosition="left"
          onClick={(e) => {
            e.stopPropagation();
            handleSingleApproveClicked(rowData, () => invalidateEditPayrollCache(queryClient));
          }}>
          APPROVE
        </Button>
      </Box>
    );
  };

  const tableTopContent = tableData.length ? (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <Box display="flex" gap="spacing.5">
        <Checkbox
          onChange={({ isChecked }) => {
            setTableData((data) =>
              data.map((rowData) => ({
                ...rowData,
                isChecked: rowData.allowAction ? isChecked : false,
              })),
            );
          }}
          isDisabled={tableData.reduce((prev, rowData) => prev && !rowData.allowAction, true)}>
          <></>
        </Checkbox>
        <Text weight="semibold" color="surface.text.gray.muted">
          Showing {data?.from}-{data?.to} of {data?.total} pending approvals
        </Text>
      </Box>
      <Box display="flex" gap="spacing.4" alignItems="center">
        <Link
          icon={RefreshIcon}
          variant="button"
          size="large"
          onClick={(e) => {
            e.preventDefault();
            refetch();
          }}
          marginRight="spacing.2"
        />
        <Button
          variant="secondary"
          onClick={() => {
            handleMultiRejectCtaClicked(() => invalidateEditPayrollCache(queryClient));
          }}
          icon={CloseIcon}
          iconPosition="left"
          isDisabled={actionCtasDisabled}>
          Reject {selectedRowsCount > 0 ? `(${selectedRowsCount})` : ''}
        </Button>
        <Button
          onClick={() => {
            handleMultiApproveCtaClicked(() => invalidateEditPayrollCache(queryClient));
          }}
          icon={CheckCircleIcon}
          iconPosition="left"
          isDisabled={actionCtasDisabled}>
          Approve {selectedRowsCount > 0 ? `(${selectedRowsCount})` : ''}
        </Button>
      </Box>
    </Box>
  ) : null;

  const tableTopSkeletonContent = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={['spacing.5', 'spacing.8', 'spacing.6']}
      borderBottomColor="surface.border.gray.normal"
      borderBottomWidth="thin">
      <AllRowsCheckSkeleton withCheckbox={true} />
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflowY="auto"
      height={tableData.length === 0 || isError ? '100%' : 'auto'}>
      {isFetching && <ProgressBar isIndeterminate />}
      {isLoading ? tableTopSkeletonContent : tableTopContent}
      <Box display="flex" overflowY="scroll" flexGrow={1}>
        <Table
          columns={editPayrollPendingTableColumns}
          data={tableData.map((data) => ({
            ...data,
            isClicked: data.id === Number(workflowRequestId),
          }))}
          onRowChecked={onRowChecked}
          onRowClick={onRowClicked}
          hoverTray={hoverTray}
          isLoading={isLoading}
          isFetching={isFetching}
          isError={isError}
          noResultsView={
            <NoResultsTableView
              onRefresh={refetch}
              workflowExists={actionsWithActiveWorkflows.includes(WorkflowTypesMap.EDIT_PAYROLL)}
              workflowType={WorkflowTypesMap.EDIT_PAYROLL}
            />
          }
          errorResultsView={<ErrorResultsTableView onRetry={refetch} />}
        />
      </Box>
      {tableData.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={tableData.length ?? 0}
          itemsPerPage={itemsPerPage}
          onCurrentPageChange={setCurrentPage}
          onItemsPerPageChange={setItemsPerPage}
          isDisabled={isLoading}
        />
      )}
    </Box>
  );
};

export { PendingApproval };
