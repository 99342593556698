import { Button, Divider, Link, TextArea, TextInput, useToast } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { helpArticles, salaryComponentParams } from 'components/SalaryComponents/constants';
import { useComponentParams, useDetailsView } from 'components/SalaryComponents/hooks';
import { EarningPayType } from 'components/SalaryComponents/types';
import {
  earningPayTypes,
  earningsValidationSchema,
  knowMoreItems,
} from 'components/WizardViews/SalaryComponents/constants';
import {
  FieldGroup,
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
  SwitchDescription,
  SwitchField,
} from 'components/WizardViews/components';
import { useFormWithProvider } from 'forms';
import React, { FormEvent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppError } from 'utils/AppError';
import { EarningsFormData } from '../types';
import {
  checkIsComponentNameBasicOrDa,
  convertFormDataToEarningSalaryComponentUpdatePayload,
  earningsExemptionDisplayText,
  isTaxExempt,
} from '../utils';
import ConfigurationCard from './ConfigurationCard';
import ConfigurationItem from './ConfigurationItem';

const GeneralDetailsStep: React.FC = () => {
  const { formData, validate, setIsSubmitting, register } = useFormWithProvider<EarningsFormData>();
  const history = useHistory();
  const toast = useToast();
  const { componentId } = useComponentParams();
  const { openDetailsView } = useDetailsView(salaryComponentParams.knowMore);

  const updateEarnings = useMutation({
    mutationFn: api.salaryComponents.mutations.updateSalaryComponent,
    onError: (e: AppError) => {
      toast.show({
        content: 'There was an error in updating salary component.',
        color: 'negative',
      });
    },
    onMutate() {
      setIsSubmitting(true);
    },
    onSettled() {
      setIsSubmitting(false);
    },
    onSuccess(data) {
      toast.show({
        content: 'Salary component updated successfully!',
        color: 'positive',
      });
      history.push(routePaths.salaryComponents.root);
    },
  });

  const isFormValid = useMemo(
    () => validate(earningsValidationSchema, { sideEffects: false }),
    [validate],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (componentId) {
      updateEarnings.mutate(
        convertFormDataToEarningSalaryComponentUpdatePayload(formData, componentId),
      );
    }
  };

  const selectedPayFrequency = formData.payType;
  const isExemptionVisible = isTaxExempt(
    formData.taxExemptInNewRegime,
    formData.taxExemptInOldRegime,
  );

  const isComponentBasicOrDa = checkIsComponentNameBasicOrDa(formData.name);

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Basic & pay" subtitle="Configuration" />
        <ConfigurationCard href={helpArticles.incomeTaxArticale}>
          <ConfigurationItem label="Component name" value={formData.name ?? ''} />
          <ConfigurationItem
            label="Pay type"
            value={
              selectedPayFrequency
                ? earningPayTypes[selectedPayFrequency as EarningPayType]?.label
                : ''
            }
          />
          <ConfigurationItem label="Calculation" value={formData.defaultFormula ?? 'N/A'} />
          <ConfigurationItem
            label="Exempted under new regime"
            value={formData.taxExemptInNewRegime ? 'Yes' : 'No'}
          />
          <ConfigurationItem
            label="Exempted under old regime"
            value={formData.taxExemptInOldRegime ? 'Yes' : 'No'}
          />
          {isExemptionVisible && (
            <ConfigurationItem
              label="Exemption section"
              value={earningsExemptionDisplayText(formData.taxExemptionSection?.[0]) ?? ''}
            />
          )}
        </ConfigurationCard>

        <FieldGroup
          title="Basic Details"
          subtitle="Modify the display name, display name and description for this component. Changes apply to your next payslip">
          <TextInput
            label="Display Name"
            placeholder="Display Name"
            {...register('displayName')}
            maxCharacters={50}
          />

          <TextArea
            label="Description"
            placeholder="Description"
            {...register('description')}
            maxCharacters={255}
          />
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup title="Proration & Arrears">
          <SwitchField
            label="Prorate the component"
            accessibilityLabel="Proration"
            size="small"
            {...register('supportProration', 'switch')}>
            <SwitchDescription content="The pay will be adjusted based on the number of working days, such as for loss of pay, joining date." />
            <Link
              variant={'button'}
              marginLeft="spacing.2"
              size="small"
              onClick={() => openDetailsView(knowMoreItems.proration)}>
              Know more✨
            </Link>
          </SwitchField>
          <SwitchField
            label="Calculate arrears"
            accessibilityLabel="Calculate Arrears"
            size="small"
            {...register('considerInArrear', 'switch')}
            isComingSoon
            isDisabled>
            <SwitchDescription content="Choose whether to calculate arrears for this component in case of missed payments or salary revisions." />
            <Link
              variant={'button'}
              marginLeft="spacing.2"
              size="small"
              onClick={() => openDetailsView(knowMoreItems.arrearCalculation)}>
              Know more✨
            </Link>
          </SwitchField>
        </FieldGroup>

        <Divider marginY="spacing.8" />

        <FieldGroup
          title="Wage calculation"
          subtitle="Define if the component is included in the calculation of statutory wages."
          infoLabel="Know more✨"
          onInfoClick={() => openDetailsView(knowMoreItems.wageCalculation)}>
          <SwitchField
            label="Part of PF wage "
            accessibilityLabel="Part of PF wage"
            size="small"
            {...register('includeInPfWages', 'switch')}>
            <SwitchDescription
              content={isComponentBasicOrDa ? '' : 'When Basic is less than 15k'}
            />
          </SwitchField>
          <SwitchField
            label="Part of ESI wage"
            accessibilityLabel="Part of ESI wage"
            size="small"
            {...register('includeInEsiWages', 'switch')}
          />
          <SwitchField
            label="Part of PT wage"
            accessibilityLabel="Part of PT wage"
            size="small"
            {...register('includeInPtWages', 'switch')}
          />
          <SwitchField
            label="Part of LWF wage"
            accessibilityLabel="Part of LWF wage"
            size="small"
            {...register('includeInLwfWages', 'switch')}
          />
        </FieldGroup>
      </StepContentWrapper>
      <StepActionsWrapper>
        <span />
        <Button type="submit" isDisabled={!isFormValid} isLoading={updateEarnings.isLoading}>
          Save component
        </Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default GeneralDetailsStep;
