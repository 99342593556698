import React from 'react';

import Modal from 'components/ui/Modals/components/Modal';
import { useModal } from 'components/ui/Modals';
import { StandardSecondaryButton } from 'components/ui/Button';
import { toIndianCurrencyString } from 'utils/Numbers';
import { DeductionComponent, DeductFrom } from '../utils/types';

import styles from './index.module.scss';

const DeductionsDisplay = ({ deductions }: { deductions: DeductionComponent }) => {
  const { closeModal } = useModal();
  const isLossOfpayPresent =
    deductions.lop_deduction && parseFloat(deductions.lop_deduction.lop_days) > 0;
  const isNonLOPDeductionPresent =
    deductions.non_lop_deductions && deductions.non_lop_deductions.length > 0;

  const getDeductFromText = (deductFrom: number) => {
    if (deductFrom === DeductFrom.NET_PAY) {
      return 'Net Pay';
    }

    return 'Gross Pay';
  };

  return (
    <Modal>
      <div className={`flex flex-col max-w-xl ${styles['run-payroll-modal']}`}>
        <div className={`text-4xl font-bold text-white ${styles['run-payroll-modal-header']}`}>
          Deduction details
        </div>
        <div className={`${styles['run-payroll-modal-content']}`}>
          <table className="w-full">
            <tr>
              <th>Label</th>
              <th>Amount</th>
              <th>Deduct From</th>
            </tr>

            {isLossOfpayPresent && (
              <tr>
                <td>Loss of Pay({deductions.lop_deduction.lop_days} days)</td>
                <td>{toIndianCurrencyString(deductions.lop_deduction.amount, true)}</td>
                <td>Gross Pay</td>
              </tr>
            )}
            {isNonLOPDeductionPresent &&
              deductions.non_lop_deductions.map((deduction) => {
                return (
                  <tr>
                    <td>{deduction.name}</td>
                    <td>{toIndianCurrencyString(deduction.amount, true)}</td>
                    <td>{getDeductFromText(deduction.deduct_from)}</td>
                  </tr>
                );
              })}
          </table>
        </div>
        <div className={`flex justify-end ${styles['run-payroll-modal-footer']}`}>
          <StandardSecondaryButton onClick={() => closeModal()}>CLOSE</StandardSecondaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeductionsDisplay;
