import { useSearchParams } from 'hooks/useSearchParams';

type UseFilterProps<T extends string> = {
  filtersMap: Record<string, T>;
  multipleValueFilters?: T[];
};

const useFilters = <T extends string>({
  filtersMap,
  multipleValueFilters = [],
}: UseFilterProps<T>) => {
  const { addSearchParams, removeSearchParams, urlParams } = useSearchParams();

  const applyFilter = (filter: T, value: string | string[]) => {
    addSearchParams([{ key: filter, value }]);
  };

  const applyFilters = (filters: { filter: T; value: string | string[] }[]) => {
    addSearchParams(filters.map(({ filter, value }) => ({ key: filter, value })));
  };

  const removeFilter = (filter: T) => {
    removeSearchParams([filter]);
  };

  const currentFilters: {
    [key in T]?: any;
  } = {};

  Object.entries(filtersMap).forEach(([_, filter]) => {
    const filterValue = multipleValueFilters.includes(filter as T)
      ? urlParams.getAll(filter as T)
      : urlParams.get(filter as T);
    if (Array.isArray(filterValue) && filterValue.length > 0) {
      currentFilters[filter as T] = filterValue;
    } else if (filterValue) {
      currentFilters[filter as T] = filterValue;
    }
  });

  const isAnyFilterApplied =
    Object.entries(currentFilters).some(([key, value]) => {

      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return !!value;
    });

  return {
    applyFilter,
    applyFilters,
    removeFilter,
    isAnyFilterApplied,
    urlParams,
    currentFilters,
  };
};

export { useFilters };
