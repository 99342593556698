import { Box, Heading } from '@razorpay/blade/components';
import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';

export const Header = () => {
  const firstName = useSelector(loggedInUserSelectors.name)?.split(' ')[0];

  return (
    <Box display={'flex'} flexDirection={'row'} gap={'spacing.1'}>
      <Heading size="large" weight="semibold" color="surface.text.gray.subtle" textAlign="center">
        {`${firstName}'s Pay`}
      </Heading>
      <Heading size="large" weight="regular" color="surface.text.gray.muted" textAlign="center">
        Details
      </Heading>
    </Box>
  );
};