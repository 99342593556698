import React, { useState } from 'react';
import {
  ActionList,
  ActionListItem,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Collapsible,
  CollapsibleBody,
  Dropdown,
  DropdownOverlay,
  Link,
  SelectInput,
} from '@razorpay/blade/components';
import {
  earningFilersLabelMap,
  earningFilterWithOptionLabelMap,
  earningFiltersMap,
  earningPayTypeFilters,
  taxabilityFilters,
} from 'components/SalaryComponents/constants';
import { useFilters } from 'hooks/useFilters';
import FilterTags from 'components/ui/FilterTag/FilterTags';
import StatusFilter from './StatusFilter';

const EarningsFilters = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currentFilters, applyFilter, isAnyFilterApplied } = useFilters({
    filtersMap: earningFiltersMap,
    multipleValueFilters: [earningFiltersMap.taxability],
  });

  return (
    <>
      <Box
        paddingX="spacing.8"
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingTop="spacing.7"
        alignItems={'center'}>
        <StatusFilter />
        <Link
          variant="button"
          icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          iconPosition="right"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}>
          {`View ${isExpanded ? 'less' : 'more'}`}
        </Link>
      </Box>
      <Collapsible isExpanded={isExpanded}>
        <CollapsibleBody width={'100%'} _hasMargin={false}>
          <Box
            paddingX="spacing.8"
            display={'flex'}
            flexDirection={'row'}
            gap={'spacing.5'}
            paddingTop={'spacing.6'}
            marginBottom={'spacing.5'}>
            <Box width={'180px'}>
              <Dropdown selectionType="single" _width={'100%'}>
                <SelectInput
                  name={earningFiltersMap.payType}
                  label=""
                  placeholder="Pay Type"
                  value={currentFilters[earningFiltersMap.payType] ?? ''}
                  onChange={({ values }) => applyFilter(earningFiltersMap.payType, values[0])}
                  accessibilityLabel="pay type filter"
                />
                <DropdownOverlay>
                  <ActionList>
                    {Object.entries(earningPayTypeFilters).map(([filterKey, filterValue]) => {
                      return (
                        <ActionListItem
                          key={filterKey}
                          title={filterValue}
                          value={filterKey}
                          testID={`pay type option ${filterValue.toLowerCase()}`}
                        />
                      );
                    })}
                  </ActionList>
                </DropdownOverlay>
              </Dropdown>
            </Box>
            <Dropdown selectionType="multiple" _width={'220px'}>
              <SelectInput
                name={earningFiltersMap.taxability}
                label=""
                placeholder="Taxability"
                value={currentFilters[earningFiltersMap.taxability]}
                onChange={({ values }) => applyFilter(earningFiltersMap.taxability, values)}
                accessibilityLabel="taxability filter"
              />
              <DropdownOverlay minWidth='250px'>
                <ActionList>
                  {Object.entries(taxabilityFilters).map(([filterKey, filterValue]) => {
                    return (
                      <ActionListItem
                        key={filterKey}
                        title={filterValue}
                        value={filterKey}
                        testID={filterValue}
                      />
                    );
                  })}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          </Box>
        </CollapsibleBody>
      </Collapsible>
      <Box paddingLeft={'spacing.3'}>
        {isAnyFilterApplied && (
          <FilterTags
            filtersMap={Object.fromEntries(
              Object.entries(earningFiltersMap).filter(([key, value]) => {
                return key !== 'status';
              }),
            )}
            filterValueLabelMap={earningFilterWithOptionLabelMap}
            multipleValueFilters={[earningFiltersMap.taxability]}
            filterLabelMap={earningFilersLabelMap}
          />
        )}
      </Box>
    </>
  );
};

export default EarningsFilters;
