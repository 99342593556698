import runpayrollApi from 'api/runPayroll';
import lockIcon from 'assets/icons/icon_lock_empty_grey.svg';
import { FETCH_LOP_AMOUNT } from 'components/RunPayroll/utils/queries';
import { DeductionComponentV2 } from 'components/RunPayroll/utils/types';
import useNewJoinerArrearEnabled from 'components/RunPayroll/utils/useNewJoinerArrearEnabled';
import ExternalLink from 'components/ui/ExternalLink';
import { Input, InputElement } from 'components/ui/Form/Input';
import InputInfo from 'components/ui/Form/Input/InputInfo';
import React from 'react';
import { useQuery } from 'react-query';
import { getStaticMediaUrl } from 'utils/Urls';
import styles from '../../index.module.scss';
import useLopTexts from '../../useLopTexts';
import { EditPayrollProps } from '../types';
import EditDeductionsV2 from '../../EditDeductionsV2';
import useIsFeatureDisabledForRearch from 'access/useIsFeatureDisabledForRearch';

type DeductionsProps = EditPayrollProps & {
  deductions: DeductionComponentV2;
  onLOPDaysChange: (value: string) => void;
  setNonLOPDeductions: (nonLOPDeductions: DeductionComponentV2['non_lop_deductions']) => void;
};

const DeductionsV2 = ({
  payrollEntry,
  payrollMonth,
  deductions,
  onLOPDaysChange,
  setNonLOPDeductions,
}: DeductionsProps) => {
  const { newJoinerArrearPaymentMonthExists } = useNewJoinerArrearEnabled(payrollEntry);

  const lopDeduction = deductions.lop_deduction;
  const { isLOPDisabled } = useIsFeatureDisabledForRearch();
  const getLOPAmount = useQuery(
    [FETCH_LOP_AMOUNT, payrollEntry.id],
    () => {
      if (!payrollEntry.id || !lopDeduction) {
        return null;
      }

      const lopDays = lopDeduction.lop_days;
      return runpayrollApi.getLOPAmount(payrollEntry.id, lopDays ? parseFloat(lopDays) : 0);
    },
    { enabled: !isLOPDisabled },
  );

  const lopAmountData = getLOPAmount.data;
  let lopAmountStatus = getLOPAmount.status;
  if (getLOPAmount.isFetching) {
    lopAmountStatus = 'loading';
  }

  const [isLOPEditable, lopText, lopAmountText, lopValue] = useLopTexts({
    lopAmountData,
    lopAmountStatus,
    lopDeduction,
    payrollId: payrollEntry.id,
  });

  return (
    <div>
      <label className={styles['run-payroll-edit-model-deduction-subheading']}>
        Don't enter any{' '}
        <ExternalLink
          href="https://intercom.help/XPayroll/en/articles/5160716-how-can-we-verify-our-compliance-deductions-before-executing-payroll"
          target="_blank"
          className={styles['run-payroll-edit-model-deduction-links']}>
          compliance
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink
          href="https://intercom.help/XPayroll/en/articles/5279075-how-to-pay-and-recover-a-salary-advance"
          target="_blank"
          className={styles['run-payroll-edit-model-deduction-links']}>
          advance
        </ExternalLink>{' '}
        deductions here
      </label>
      <InputElement label={'Loss of pay days'} className={` mt-8 mb-10 ${styles['label']}`}>
        <div className={styles['run-payroll-edit-lop-days-input']}>
          <Input
            type="number"
            maxLength={10}
            id="deduction-days"
            value={lopValue}
            data-testid={'deduction-days'}
            placeholder="Or number of days of salary to deduct"
            disabled={!isLOPEditable || getLOPAmount.isLoading || getLOPAmount.isFetching}
            onChange={(event) => {
              onLOPDaysChange(event.target.value);
            }}
            onBlur={(event) => {
              if (!isLOPDisabled) {
                getLOPAmount.refetch();
              }
            }}></Input>
          {!isLOPEditable && <img className="pr-3" src={getStaticMediaUrl(lockIcon)} />}
        </div>
        <InputInfo>
          {lopText} Understand calculations{' '}
          <a
            href="https://intercom.help/XPayroll/en/articles/8512408-deductions-loss-of-pay-days"
            target="_blank"
            rel="noreferrer">
            here.
          </a>
        </InputInfo>
        <div className={styles['run-payroll-lop-days-text']} data-testid="lop-message">
          {lopAmountText}
        </div>
      </InputElement>
      {!newJoinerArrearPaymentMonthExists && (
        <EditDeductionsV2 deductions={deductions} setNonLOPDeductions={setNonLOPDeductions} />
      )}
    </div>
  );
};

export default DeductionsV2;
