import React from 'react';
import { Box, Switch, Text, Divider } from '@razorpay/blade/components';
import { Reveal } from 'components/ui/Reveal/Reveal';

interface SwitchInputProps {
  label: string;
  mainText: string;
  subText: string;
  hiddenTray?: React.ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  name: string;
  onChange?: ({
    isChecked,
    value,
    event,
  }: {
    isChecked: boolean;
    event?: React.ChangeEvent;
    value?: string;
  }) => void;
}

const SwitchSettingsCard = ({
  label,
  mainText,
  subText,
  hiddenTray,
  name,
  isChecked = false,
  isDisabled,
  onChange,
}: SwitchInputProps) => {
  return (
    <Box
      borderWidth="thin"
      borderRadius="medium"
      padding="spacing.7"
      borderColor="surface.border.gray.subtle"
      backgroundColor="surface.background.gray.moderate">
      <Box display="flex" gap="spacing.8" justifyContent="space-between">
        <Box>
          <Text weight="semibold" color="surface.text.gray.normal">
            {mainText}
          </Text>
          <Text
            variant="caption"
            marginTop="spacing.2"
            color="surface.text.gray.muted"
            size="small">
            {subText}
          </Text>
        </Box>
        <Switch
          size="small"
          accessibilityLabel={label}
          alignSelf="flex-start"
          isChecked={isChecked}
          onChange={onChange}
          name={name}
          value={name}
          isDisabled={isDisabled}
        />
      </Box>
      {hiddenTray && (
        <Reveal isOpen={isChecked}>
          <Box marginY={'spacing.7'}>
            <Divider />
          </Box>
          {hiddenTray}
        </Reveal>
      )}
    </Box>
  );
};

export default SwitchSettingsCard;
