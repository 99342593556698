import React from 'react';
import { Box, Text } from '@razorpay/blade/components';

type HeaderSectionProps = {
  title: string;
  description: string;
};
const HeaderSection = ({ title, description }: HeaderSectionProps) => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'spacing.4'}>
      <Text size={'large'} weight={'semibold'}>
        {title}
      </Text>
      <Text size="small" color={'surface.text.gray.muted'}>
        {description}
      </Text>
    </Box>
  );
};

export default HeaderSection;