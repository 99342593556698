import { Box, Breadcrumb, BreadcrumbItem } from '@razorpay/blade/components';
import PageLayout from 'components/ui/Layout/PageLayout';
import React from 'react';
import { useSelector } from 'react-redux';
import loggedInUserSelectors from 'reducers/loggedInUser/selectors';
import { getEmployeeDetailsLink, getPayDetailsLink, getPeoplePage } from 'utils/links';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routePaths } from 'components/Routes/data';
import { useSearchParams } from 'hooks/useSearchParams';
import { CompensationView } from 'components/PayDetails/views/CompensationView';
import { Header } from '../components/Header';
import { HeaderTabs } from '../components/HeaderTabs';

export const PayDetailsView = () => {
  const { urlParams } = useSearchParams();
  const peopleId = Number(urlParams.get('userId'));
  const firstName = useSelector(loggedInUserSelectors.name)?.split(' ')[0];

  return (
    <>
      <PageLayout
        hideHeading
        withoutBackgroundImg
        boxProps={{
          marginX: 'spacing.4',
          marginY: 'spacing.7',
          width: 'initial',
        }}
        breadcrumb={
          <Breadcrumb color="neutral">
            <BreadcrumbItem href={getPeoplePage()}>People</BreadcrumbItem>
            <BreadcrumbItem href={getEmployeeDetailsLink(peopleId)}>
              {firstName || '...'}
            </BreadcrumbItem>
            <BreadcrumbItem href={getPayDetailsLink()} isCurrentPage>
              Pay
            </BreadcrumbItem>
          </Breadcrumb>
        }>
        <Box
          display={'flex'}
          flexDirection={'column'}
          marginTop={'spacing.8'}
          gap={'spacing.7'}
          paddingLeft={{ xl: '124px' }}
          paddingRight={{ xl: '192px' }}
          maxWidth={'1224px'}
          width={'100%'}
          marginX={'auto'}>
          <Header />
          <HeaderTabs />
          <Switch>
            <Route path={routePaths.payDetails.root} exact>
              <Redirect to={routePaths.payDetails.compensation} />
            </Route>
            <Route path={routePaths.payDetails.compensation} component={CompensationView} exact />
            <Route path={routePaths.payDetails.payslips} component={() => <></>} exact />
            <Route path={routePaths.payDetails.form16} component={() => <></>} exact />
            <Route>
              <Redirect to={routePaths.unknown} />
            </Route>
          </Switch>
        </Box>
      </PageLayout>
    </>
  );
};
