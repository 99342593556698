import React, { useState } from 'react';
import {
  ActionList,
  ActionListItem,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Collapsible,
  CollapsibleBody,
  Dropdown,
  DropdownOverlay,
  Link,
  SelectInput,
} from '@razorpay/blade/components';
import {
  deductionFilersLabelMap,
  deductionFilterWithOptionLabelMap,
  deductionFiltersMap,
  deductionTypeFilters,
  taxabilityFilters,
} from 'components/SalaryComponents/constants';
import { useFilters } from 'hooks/useFilters';
import FilterTags from 'components/ui/FilterTag/FilterTags';
import StatusFilter from './StatusFilter';

const DeductionsFilters = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { currentFilters, applyFilter, isAnyFilterApplied } = useFilters({
    filtersMap: deductionFiltersMap,
    multipleValueFilters: [deductionFiltersMap.taxability],
  });

  return (
    <>
      <Box
        display={'flex'}
        paddingX="spacing.8"
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingTop="spacing.7"
        alignItems={'center'}>
        <StatusFilter />
        <Link
          variant="button"
          icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          iconPosition="right"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}>
          View {isExpanded ? 'less' : 'more'}
        </Link>
      </Box>
      <Collapsible isExpanded={isExpanded}>
        <CollapsibleBody width={'100%'} _hasMargin={false}>
          <Box
            paddingX="spacing.8"
            display={'flex'}
            flexDirection={'row'}
            gap={'spacing.5'}
            marginTop={'spacing.6'}
            marginBottom={'spacing.5'}>
            <Box width={'180px'}>
              <Dropdown selectionType="single" _width={'100%'}>
                <SelectInput
                  name={deductionFiltersMap.deduction_type}
                  label=""
                  placeholder="Deduction Type"
                  value={currentFilters[deductionFiltersMap.deduction_type] ?? ''}
                  onChange={({ values }) =>
                    applyFilter(deductionFiltersMap.deduction_type, values[0])
                  }
                  accessibilityLabel="deduction type filter"
                />
                <DropdownOverlay>
                  <ActionList>
                    {Object.entries(deductionTypeFilters).map(([filterKey, filterValue]) => {
                      return (
                        <ActionListItem key={filterKey} title={filterValue} value={filterKey} />
                      );
                    })}
                  </ActionList>
                </DropdownOverlay>
              </Dropdown>
            </Box>
            <Dropdown selectionType="multiple" _width={'220px'}>
              <SelectInput
                name={deductionFiltersMap.taxability}
                label=""
                placeholder="Taxability"
                value={currentFilters[deductionFiltersMap.taxability]}
                onChange={({ values }) => applyFilter(deductionFiltersMap.taxability, values)}
                accessibilityLabel="taxability filter"
              />
              <DropdownOverlay minWidth='250px'>
                <ActionList>
                  {Object.entries(taxabilityFilters).map(([filterKey, filterValue]) => {
                    return <ActionListItem key={filterKey} title={filterValue} value={filterKey} />;
                  })}
                </ActionList>
              </DropdownOverlay>
            </Dropdown>
          </Box>
        </CollapsibleBody>
      </Collapsible>
      <Box paddingLeft={'spacing.3'}>
        {isAnyFilterApplied && (
          <FilterTags
            filtersMap={Object.fromEntries(
              Object.entries(deductionFiltersMap).filter(([key, value]) => {
                return key !== 'status';
              }),
            )}
            filterValueLabelMap={deductionFilterWithOptionLabelMap}
            multipleValueFilters={[deductionFiltersMap.taxability]}
            filterLabelMap={deductionFilersLabelMap}
          />
        )}
      </Box>
    </>
  );
};

export default DeductionsFilters;
