import { Alert, ArrowLeftIcon, Button, useToast } from '@razorpay/blade/components';
import api from 'api';
import { routePaths } from 'components/Routes/data';
import { useComponentParams } from 'components/SalaryComponents/hooks';
import {
  FormWrapper,
  StepActionsWrapper,
  StepContentWrapper,
  StepHeader,
} from 'components/WizardViews/components';
import {
  ReviewSection,
  ReviewSectionContainer,
  ReviewSectionHeader,
  ReviewSectionItem,
  ReviewSectionItemContainer,
} from 'components/WizardViews/components/review';
import { useFormWithProvider } from 'forms';
import React, { FormEvent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { AppError } from 'utils/AppError';
import { deductionsValidationSchema } from '../constants';
import { DeductionsFormData } from '../types';
import {
  convertFormDataToDeductionSalaryComponentUpdatePayload,
  deductionsExemptionDisplayText,
} from '../utils';

const ReviewStep: React.FC = () => {
  const { formData, validate, setIsSubmitting } = useFormWithProvider<DeductionsFormData>();
  const history = useHistory();
  const toast = useToast();
  const { searchString, componentId } = useComponentParams();

  const updateDeductions = useMutation({
    mutationFn: api.salaryComponents.mutations.updateSalaryComponent,
    onError: (e: AppError) => {
      toast.show({
        content: 'There was an error in updating salary component.',
        color: 'negative',
      });
    },
    onMutate() {
      setIsSubmitting(true);
    },
    onSettled() {
      setIsSubmitting(false);
    },
    onSuccess(data) {
      toast.show({
        content: 'Salary component updated successfully!',
        color: 'positive',
      });
      history.push(routePaths.salaryComponents.deductionsListView);
    },
  });

  const isFormValid = useMemo(
    () => validate(deductionsValidationSchema, { sideEffects: false }),
    [validate],
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (componentId) {
      updateDeductions.mutate(
        convertFormDataToDeductionSalaryComponentUpdatePayload(formData, componentId),
      );
    }
  };

  const editGeneralDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.edit.deductions.generalDetails,
      search: searchString,
    });

  const editPayAndTaxationDetailsClick = () =>
    history.push({
      pathname: routePaths.salaryComponents.edit.deductions.payAndTaxDetails,
      search: searchString,
    });

  const exemptionDisplayText = formData.taxExemptionSection
    ? deductionsExemptionDisplayText(formData.taxExemptionSection[0])
    : '';

  const isRecurring = formData?.deductionType === 'RECURRING';
  const isAdhoc = formData?.deductionType === 'ADHOC';

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <StepContentWrapper>
        <StepHeader title="Review" subtitle="Component" />

        {/* TODO: Alert control and message to be updated */}
        {updateDeductions.error && (
          <Alert
            marginBottom="spacing.7"
            title="The component cannot be created because of the below errors:"
            description={updateDeductions.error.message}
            color="negative"
            isFullWidth
            isDismissible={false}
          />
        )}

        <ReviewSectionContainer>
          <ReviewSection>
            <ReviewSectionHeader
              title={'General & Type'}
              subtitle={'details'}
              onEditClick={editGeneralDetailsClick}
            />
            <ReviewSectionItemContainer>
              <ReviewSectionItem label={'Component Name'} value={formData.name} />
              <ReviewSectionItem
                label="Payslip & Salary Register name"
                value={formData.displayName}
              />
              <ReviewSectionItem label="Component Description" value={formData.description} />
              <ReviewSectionItem
                label="Deduction Type"
                value={isRecurring ? 'Recurring(Monthly)' : 'Adhoc'}
              />
            </ReviewSectionItemContainer>
          </ReviewSection>
          <ReviewSection>
            <ReviewSectionHeader
              title={'Pay & Taxability'}
              subtitle={'details'}
              onEditClick={editPayAndTaxationDetailsClick}
            />
            <ReviewSectionItemContainer>
              {isAdhoc && <ReviewSectionItem label={'Deduct From'} value={formData.deductFrom} />}
              {isRecurring && (
                <>
                  <ReviewSectionItem
                    label="Prorate the component"
                    value={formData.supportProration ? 'Yes' : 'No'}
                  />
                  <ReviewSectionItem
                    label="Calculate arrears"
                    value={formData.considerInArrear ? 'Yes' : 'No'}
                  />
                </>
              )}
              <ReviewSectionItem
                label="Tax exempted in Old Regime"
                value={formData.taxExemptInOldRegime ? 'Yes' : 'No'}
              />
              <ReviewSectionItem
                label="Tax exempted in New Regime"
                value={formData.taxExemptInNewRegime ? 'Yes' : 'No'}
              />
              <ReviewSectionItem label="Exemption under" value={exemptionDisplayText} />
            </ReviewSectionItemContainer>
          </ReviewSection>
        </ReviewSectionContainer>
      </StepContentWrapper>
      <StepActionsWrapper>
        <Button
          variant="secondary"
          icon={ArrowLeftIcon}
          onClick={() => history.goBack()}
          isDisabled={updateDeductions.isLoading}>
          Back: Pay & Taxability
        </Button>
        <Button type="submit" isDisabled={!isFormValid} isLoading={updateDeductions.isLoading}>
          Update Component
        </Button>
      </StepActionsWrapper>
    </FormWrapper>
  );
};

export default ReviewStep;
